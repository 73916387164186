@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("./Montserrat-Regular.woff2");
  src: url("./Montserrat-Regular.woff");
  src: url("./Montserrat-Regular.eot");
  src: url("./Montserrat-Regular.eot?iefix") format("embedded-opentype");
  src: url("./Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url("./Montserrat-Medium.woff2");
  src: url("./Montserrat-Medium.woff");
  src: url("./Montserrat-Medium.eot");
  src: url("./Montserrat-Medium.eot?iefix") format("embedded-opentype");
  src: url("./Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: url("./Montserrat-SemiBold.woff2");
  src: url("./Montserrat-SemiBold.woff");
  src: url("./Montserrat-SemiBold.eot");
  src: url("./Montserrat-SemiBold.eot?iefix") format("embedded-opentype");
  src: url("./Montserrat-SemiBold.ttf") format("truetype");
}
